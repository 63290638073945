import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Services/img_1.jpg'
import image_2 from '../../image/Services/img_2.jpg'
import image_3 from '../../image/Services/img_3.jpg'
import image_4 from '../../image/Services/img_4.jpg'
import image_5 from '../../image/Services/img_5.jpg'
import image_6 from '../../image/Services/img_6.jpg'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Services = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='Services' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}><span>Производим навесы </span> для частных лиц и компаний</motion.h2>
        <motion.ul className={styles.list}>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_1} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Навес для автомобиля</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_2} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Навес к дому</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_3} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Навес над входом</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_4} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>Навес для террасы</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_5} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>навес для отдыха</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
            <motion.li 
                className={styles.cell}
                variants={cardAnimation}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.05, once: true}}
                >
                <div className={styles.cell_box}>
                    <img className={styles.cell_image} src={image_6} alt='стружка'/>
                </div>
                <div className={styles.cell_info}>
                    <h3 className={styles.cell_subtitle}>навес для бассейна</h3>
                    <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
                </div>
            </motion.li>
        </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Services