import React from 'react'
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'

const Feedback = () => {


    return (
        <section className={styles.main} id='Reviews'>
            <div className={styles.box}>
                <h2 className={styles.title}>Отзывы <span>наших заказчиков</span></h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 5000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.05,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Валерий Булеев</p>
                                        <p className={styles.comment}>18.06.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Отличная компания! Заказывал навес 9*4 метра на дачу, приехали и сделали замер через пару часов после моего звонка. Несмотря на майские праздники, через несколько дней сообщили, что конструкция навеса готова. Неделю спустя в удобное для меня время ребята приехали и установили навес. Работали старательно с 8 утра до 11 вечера. Реализовано всё, что я просил. По моей дополнительной просьбе поменяли петли и проварили старые ворота на участке. Я доволен выполненной работой!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Игорь Бессонов</p>
                                        <p className={styles.comment}>30.05.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Нам оперативно сделали замер. С момента аванса до монтажа прошло чуть более 2-х недель. Материалы завезли накануне вечером. Работы выполнили за один день. Работали тщательно, проверяя разметку. Ни растения, ни напольная плитка примыкающей беседки при монтаже навеса не пострадали. Навес получился лучше, чем мы первоначально ожидали. Естественно, что мы испытывали опасения, обращаясь к незнакомому исполнителю, но, как показал наш опыт, они не оправдались. Еще раз благодарим коллектив за качественную работу и внимательное отношение к своим заказчикам.</p>                              
                                    </div>
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                            <div className={styles.cell}>
                                <div className={styles.item}>
                                    <p className={styles.subtitle}>СЕРГЕЙ КАГАНОВ</p>
                                    <p className={styles.comment}>18.08.2022</p>
                                    <img className={styles.image} src={img} alt = 'звездочки'/>
                                    <p className={styles.comment}>Комментарий</p>
                                    <p className={styles.text}>Самый лучший подрядчик, которого я встречал за последние 5 лет. Заказывали навес для машины на дачу и сегодня, 18 Августа 2022 его установили: Четко произвели измерения, все произвели в срок, за несколько часов все установили , причём на самом высоком качестве проведения работ. Все это было сделано по Договору и именно так , как было запланировано. Огромное спасибо! Всем рекомендую!</p>                              
                                </div>
                            </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Игорь Г.</p>
                                        <p className={styles.comment}>13.09.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Спасибо огромное! Все быстро, качественно и главное дешевле чем остальные компании!!! Ребята красавцы!!! Когда остальные компании ориентировали на мес/два, ребята сделали все за неделю и смонтировали все за 2 дня! Лучшие из лучших в своём деле! Всем советую! Ещё обратимся не один раз! Ещё раз Спасибо 🙏 вы лучшие!!!!</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Николай С.</p>
                                        <p className={styles.comment}>09.10.2021</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Среагировали быстро, утром позвонил, уже к вечеру сняли замеры. Через неделю навес на месте. Установили за два дня. Качеством работы очень доволен! Сделали добросовестно! Работники с Высшим Архитектурно-строительным образованием, дело своё знают! Если возникнет вопрос с навесом, смело могу рекомендовать.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>    
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Андрей П.</p>
                                        <p className={styles.comment}>10.10.2021</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Позвонил: на следующий день приехал замерщик- согласовали цену, подписали договор - еще через 2 дня навес уже поставили. Очень оперативно! Вообще молодцы ребята, аккуратные и позитивные. Сотрудничеством доволен, рекомендую и буду обращаться к ним в дальнейшем.</p>                              
                                    </div>
                                </div>      
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Георгий Кузя</p>
                                        <p className={styles.comment}>22.07.2020</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Заказали навес на дачу, между гаражом и забором. Замер, изготовление, установка - все очень быстро и качественно. В процессе установки по месту учли все пожелания. Сотрудники позитивные, аккуратные, добросовестные. Результатом удовлетворены на все 100%!!! Большое спасибо!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}

export default Feedback