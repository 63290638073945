import React from 'react'
import styles from './InitialPage.module.scss'
import documents from '../../image/InitialPage/require-title.png'


const InitialPage = () => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>Нужен качественный и стильный навес?</p>
                    <h1 className={styles.title}><span>Производим и монтируем</span> надежные навесы в&#160;Москве&#160;и Московской области</h1>
                    <div className={styles.box}>
                        <img className={styles.documents} src={documents} alt='документы'/>
                        <p className={styles.subtitle}>Все работы с&#160;гарантией по договору</p>
                    </div>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Cобственное производство в Московской области</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Производим и монтируем качественные навесы круглый год</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Сэкономим до 20% на материалах и комплектующих</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>рассчитать стоимость</a>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage