import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Deadlines/img_1.svg'
import image_2 from '../../image/Deadlines/img_2.svg'
import image_3 from '../../image/Deadlines/img_3.svg'
import image_4 from '../../image/Deadlines/img_4.svg'
import image_5 from '../../image/Deadlines/img_5.svg'
import image_6 from '../../image/Deadlines/img_6.svg'

const Scheme = ({isOpen}) => {

    const titleAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }

    const cardAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .7}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Scheme' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как мы<span> работаем?</span></motion.h2>
            <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАЯВКА</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ВЫЕЗД ЗАМЕРЩИКА</h3>
                            <p className={styles.item_text}><span>Приедет в будни или выходные. </span>На месте он дает квалифицированную консультацию, делает необходимые замеры.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЗАКЛЮЧАЕМ ДОГОВОР</h3>
                            <p className={styles.item_text}>После согласования всех ключевых условий и сроков подписываем договор. <span>Заказчик производит предоплату 30%.</span></p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Изготовление навеса</h3>
                            <p className={styles.item_text}>Мы изготавливаем навес на собственном производстве, расположенном в МО.<span> Срок производства в среднем 14 дней.</span></p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_5} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Доставка и установка</h3>
                            <p className={styles.item_text}>В выбранный вами день мы доставляем конструкцию и производим ее установку. Срок установки составляет 1-2 дня.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_6} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Сдача работы</h3>
                            <p className={styles.item_text}>Вы оплачиваете остаток стоимости вашего навеса. <span>Мы предоставляем гарантию на конструкцию и проведенные нами работы.</span></p>
                        </motion.li>
                    </motion.ul>
                        <a 
                        className={styles.button} 
                        target='_blank' rel='noopener noreferrer' href='https://wa.me/+79998483760' aria-label='связаться вацап'>заказать навес</a>
            </div>   
        </motion.section>
        
        
    )
}

export default Scheme
