import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Confidence from '../../components/Confidence/Confidence'
import Practical from '../../components/Practical/Practical'
import Feedback from '../../components/Feedback/Feedback'
import Roof from '../../components/Roof/Roof'
import Services from '../../components/Services/Services'
import Scheme from '../../components/Scheme/Scheme'
import Types from '../../components/Types/Types'
import ObjectsNearby from '../../components/ObjectsNearby/ObjectsNearby'
import SliderAdvantages from '../../components/SliderAdvantages/SliderAdvantages'

const Main = ({isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage/>
            <SliderAdvantages/>
            <Services/>
            <Confidence/>
            <Types/>
            <Roof/>
            <ObjectsNearby
                isImageOpen={isImageOpen}
            />
            <Scheme/>
            <Feedback/>
            <Practical/>
        </section>
    )
}

export default Main