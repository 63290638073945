import React from 'react'
import styles from './MenuMobile.module.scss'
import logo from '../../image/Header/logo.png'

export const MenuMobile = ({onClose}) => {

    return (
        <div className={styles.main}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <a className={styles.link} href='#Services' onClick={onClose}>Услуги</a>
                    <a className={styles.link} href='#Advantages' onClick={onClose}>Преимущества</a>
                    <a className={styles.link} href='#Portfolio' onClick={onClose}>Портфолио</a>
                    <a className={styles.link} href='#Scheme' onClick={onClose}>Схема работы</a>
                    <a className={styles.link} href='#Reviews' onClick={onClose}>Отзывы</a>
            </nav>
        </div>
    );
}
