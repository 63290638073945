import React from 'react'
import styles from './Roof.module.scss'
import { motion } from "framer-motion"
import img_1 from '../../image/Roof/img_1.jpg'
import img_2 from '../../image/Roof/img_2.jpg'
import img_3 from '../../image/Roof/img_3.jpg'
import img_4 from '../../image/Roof/img_4.webp'
import img_5 from '../../image/Roof/img_5.jpg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Roof = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Выберите <span> цвет и материал</span> кровли под&#160;свой&#160;вкус</motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_1} alt='кровля'/>
                        <div className={styles.text_box}>
                            <p className={styles.text}>Cотовый поликарбонат</p>
                        </div>    
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_2} alt='кровля'/>
                        <div className={styles.text_box}>
                            <p className={styles.text}>Монолитный поликарбонат</p>
                        </div>     
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_3} alt='кровля'/>
                        <div className={styles.text_box}>
                            <p className={styles.text}>Профилированный поликарбонат</p>
                        </div>      
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_4} alt='кровля'/>
                        <div className={styles.text_box}>
                            <p className={styles.text}>Металлочерепица</p>
                        </div>     
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.image} src={img_5} alt='кровля'/>
                        <div className={styles.text_box}>
                            <p className={styles.text}>Профнастил</p>
                        </div>     
                    </motion.li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Roof