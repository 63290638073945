import React from 'react'
import styles from './Practical.module.scss'
import icon_1 from '../../image/Practical/img_2.webp'
import icon_2 from '../../image/Practical/img_1.webp'
import icon_3 from '../../image/Practical/img_3.webp'
import SliderAdvantages  from '../../components/SliderAdvantages/SliderAdvantages'

const Practical = () => {

    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <div className={styles.box}>
                        <h2 className={styles.title} >СОВРЕМЕННО. ПРАКТИЧНО. ДОСТУПНО.</h2>
                        <div className={styles.box_image}>
                            <img className={styles.image} src={icon_1} alt='лазер'/>
                            <img className={styles.image} src={icon_2} alt='лазер'/>
                            <img className={styles.image} src={icon_3} alt='лазер'/>
                        </div>
                        <h2 className={styles.title} >ЗАКАЖИТЕ СЕЙЧАС, СДЕЛАЕМ БЫСТРО!</h2>
                    </div>
                </div>
                <SliderAdvantages/>
            </div>
        </section>
    )
}

export default Practical

