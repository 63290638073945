import oil_1 from '../image/Portfolio/img_1.jpg'
import oil_2 from '../image/Portfolio/img_2.jpg'
import oil_3 from '../image/Portfolio/img_3.jpg'
import oil_4 from '../image/Portfolio/img_4.jpg'
import oil_5 from '../image/Portfolio/img_5.jpg'
import oil_6 from '../image/Portfolio/img_6.jpg'
import oil_7 from '../image/Portfolio/img_7.jpg'
import oil_8 from '../image/Portfolio/img_8.jpg'
import oil_9 from '../image/Portfolio/img_9.jpg'

import port_1 from '../image/Portfolio/img_1.jpg'
import port_2 from '../image/Portfolio/img_2.jpg'
import port_3 from '../image/Portfolio/img_3.jpg'
import port_4 from '../image/Portfolio/img_4.jpg'
import port_5 from '../image/Portfolio/img_5.jpg'
import port_6 from '../image/Portfolio/img_6.jpg'
import port_7 from '../image/Portfolio/img_7.jpg'
import port_8 from '../image/Portfolio/img_8.jpg'
import port_9 from '../image/Portfolio/img_9.jpg'


export const arrAdvantages = [{text:'Собственное ПРОИЗВОДСТВО!'}, {text:'Персональный ПОДХОД!'}, {text:'Гарантия КАЧЕСТВА!'}, {text:'Демократичные ЦЕНЫ!'}, {text:'Cистема СКИДОК!'}, {text:'Выезд замерщика БЕСПЛАТНО!'},];

export const arrObjects = [{image: oil_1, title: 'подробнее'}, {image: oil_2, title: 'подробнее'}, {image: oil_3, title: 'подробнее'}, {image: oil_4, title: 'подробнее'}, {image: oil_5, title: 'подробнее'}, {image: oil_6, title: 'подробнее'}, {image: oil_7, title: 'подробнее'}, {image: oil_8, title: 'подробнее'}, {image: oil_9, title: 'подробнее'},];


export const arrPortfolio = [{image:  port_1, title: 'Навес'}, {image:  port_2, title: 'Навес'}, {image:  port_3, title: 'Навес'}, {image:  port_4, title: 'Навес'}, {image:  port_5, title: 'Навес'}, {image:  port_6, title: 'Навес'}, {image:  port_7, title: 'Навес'}, {image:  port_8, title: 'Навес'}, {image:  port_9, title: 'Навес'},];



